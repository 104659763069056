import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { prevCondSelector, getMinPrice } from '../flight/baseSelector'
import { lowRPriceSelector, lowestRPriceSelector, owRecommendRtSelector, workingDaysSelector, owRecommendRtSwitchV2Selector, priceItemsSelector, owRecommendRtUserAttrSelector } from './owRecommendRtSelector'
import OwRecommendRt from '../../../../components/list/result/recommend/owRecomendRt'
import { fetchLowPrice } from '../../../../actions/channel/channel'
import { genSetOwRecommendRtEndDate, fetchUserAttr } from '../../../../actions/list/recommend'
import { isRealLogin } from '../../../../sources/list/getFragment'
import { getIfScopeDomestic } from '../../../../sources/list/scope'

class OwRecommendRtContainer extends React.Component {
    constructor(props) {
        super(props)
    }

    shouldComponentUpdate(nextProp) {
        if (this.props.prevCond.get('flightWay') != nextProp.prevCond.get('flightWay')
            || this.props.recommend.get('endDate') != nextProp.recommend.get('endDate')
            || this.props.owRecommendRtSwitchV2 != nextProp.recommend.owRecommendRtSwitchV2
            || nextProp.recommend.get('lowPriceData')
            || this.props.minPrice
        ) {
            return true
        }
        return false
    }

    componentWillReceiveProps(nextProp) {
        if (nextProp.owRecommendRtSwitchV2 != this.props.owRecommendRtSwitchV2 && nextProp.owRecommendRtSwitchV2 == true && this.props.isLogin) {
            let flightSegments = this.props.prevCond.get('flightSegments'),
                departDate = flightSegments.getIn([0, 'departureDate']),
                departureCityCode = flightSegments.getIn([0, 'departureCityCode']),
                arrivalCityCode = flightSegments.getIn([0, 'arrivalCityCode'])
            this.props.getUserAttr({
                arrivalCityCode,
                departureCityCode,
                departureDate: departDate
            })
        }

    }

    render() {

        return (
            <div>
                <OwRecommendRt {...this.props}></OwRecommendRt>
            </div>
        )
    }
}

const mapStateToProps = (state, _props) => {
    return {
        prevCond: prevCondSelector(state),
        lowRPrice: lowRPriceSelector(state),
        lowestRPrice: lowestRPriceSelector(state),
        recommend: owRecommendRtSelector(state),
        workingDays: workingDaysSelector(state),
        minPrice: getMinPrice(state),
        isLogin: isRealLogin,
        owRecommendRtSwitchV2: owRecommendRtSwitchV2Selector(state) == "E",
        priceItemsConfigs: priceItemsSelector(state),
        userAttr: owRecommendRtUserAttrSelector(state)
    }
}


const mapDispatchToProps = dispatch => ({
    //日历低价
    fetchLowPrice(dCityCode, aCityCode) {
        const scopeDomestic = getIfScopeDomestic()
        dispatch(fetchLowPrice(dCityCode, aCityCode, { domestic: scopeDomestic }))
    },
    showLowPrice() {
        return true
    },
    onEndDateChange(dateInfo) {
        dispatch(genSetOwRecommendRtEndDate(moment(dateInfo.date)))
    },
    getUserAttr(params) {
        dispatch(fetchUserAttr(params))
    }
})


export default connect(mapStateToProps, mapDispatchToProps)(OwRecommendRtContainer)
