import React from "react"
import monent from "moment"
import { LIST_UBT_KEY_TYPES } from '../../../../ubt.v2/list'

const popupCalendarFooter = ({ ...props }) => {
    let { departDate, endDate, flightLowRPrice, locationToRoundListPage } = props,
        startDate = departDate ? monent(departDate).format('MM-DD') : '- -',
        selectedDate = endDate ? endDate.format('MM-DD') : '- -',
        lowPrice = endDate && flightLowRPrice && flightLowRPrice.get(`${departDate}-${endDate.format('YYYY-MM-DD')}`) ? flightLowRPrice.get(`${departDate}-${endDate.format('YYYY-MM-DD')}`) : ''
    let onDirectFlight = () => {
        locationToRoundListPage(monent(departDate), endDate, '', lowPrice)
        LIST_UBT_KEY_TYPES.OW_RECOMMEND_RT_CLICK.value.process("ad_roundway_moredate_confirm")
    }
    return (

        <div className="cmodal-info">
            <div className="info-go">
                <span>去程：</span>
                <em>{startDate}</em>
            </div>
            <div className="info-back">
                <span>返程：</span>
                <em>{selectedDate}</em>
            </div>
            <div className="info-price">
                <span>往返总价：</span>
                <em><dfn>&yen;</dfn>{lowPrice || '- -'}</em>
            </div>
            <div onClick={onDirectFlight} className={`btn ${!endDate ? "btn-disabled" : ""}`}>查看航班</div>
        </div>
    )
}

export default popupCalendarFooter
