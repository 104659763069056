import React from 'react'
import shortid from 'shortid'
import Immutable from 'immutable'
import moment from 'moment'
import { DATE_PICKER_FOOTER_TIPS, DATE_PICKER_TITLE } from '../../../../constants/common/enum/common'
import ModifyDateInfo from '../../../Base/modifyDateInfo'
import PopDialog from '../../common/PopDialog'
import popupCalendarFooter from './popupCalendarFooter'
import { UbtBoundary, ListUbtGenerator, LIST_UBT_GROUP_TYPES, LIST_UBT_KEY_TYPES } from '../../../../ubt.v2/list'
import Converter from '../../../../sources/list/converter';
import OwRecommendRtConfig from '../../../../constants/list/owRecommendRtConfig'


class OwRecommendRt extends React.Component {
    constructor(props) {
        super(props)
        this.locationToRoundListPage = this.locationToRoundListPage.bind(this)
        this.state = {
            showCalendarPopup: false
        }
    }

    onPopupCalendar() {
        this.setState({
            showCalendarPopup: true
        })
        LIST_UBT_KEY_TYPES.OW_RECOMMEND_RT_CLICK.value.process("ad_roundway_moredate")
    }
    hideCalendarPopup() {
        this.setState({
            showCalendarPopup: false
        })
    }

    onPriceItemClick(departDate, endDate, order, price) {
        this.locationToRoundListPage(departDate, endDate, order, price)
        LIST_UBT_KEY_TYPES.OW_RECOMMEND_RT_CLICK.value.process("ad_roundway_date")
    }

    locationToRoundListPage(departDate, endDate, recommendOrder, price) {
        if (!departDate || !endDate) {
            return
        }
        let { ubtOriginFlight } = this.props;
        let ubtSourceInfoOfThis = {
            Source: 1,
            Type: 11,
            Position: 'Top',
            RecommendOrder: recommendOrder,
            Sequence: [],
            OriginFlight: ubtOriginFlight
        },
            ubtOtherParams = {
                Price: price,
                IsTax: 'T'
            },
            urlProps = {},
            flightSegments = this.props.prevCond.get("flightSegments"),
            fromCode = flightSegments.getIn([0, 'departureCityCode']),
            toCode = flightSegments.getIn([0, 'arrivalCityCode'])


        ubtSourceInfoOfThis = Object.assign(ubtSourceInfoOfThis, ubtOtherParams)
        // flightSegments.forEach(searchedFlightSegment => {
        //     ubtSourceInfoOfThis.Sequence.push({
        //         From: searchedFlightSegment.get("departureCityCode"),
        //         To: searchedFlightSegment.get("arrivalCityCode"),
        //         StartTime: searchedFlightSegment.get("departureDate")
        //     })
        // })
        let departCode = flightSegments.getIn([0, 'departureCityCode'])
        let arrivalCode = flightSegments.getIn([0, 'arrivalCityCode'])
        ubtSourceInfoOfThis.Sequence.push({
            From: departCode,
            To: arrivalCode,
            StartTime: departDate.format('YYYY-MM-DD')
        })
        ubtSourceInfoOfThis.Sequence.push({
            From: arrivalCode,
            To: departCode,
            StartTime: endDate.format('YYYY-MM-DD')
        })
        let depdate = `${departDate.format('YYYY-MM-DD')}_${endDate.format('YYYY-MM-DD')}`,
            flightWayAndCityString = `round-${fromCode}-${toCode}`.toLowerCase()

        urlProps.flightWayAndCityString = flightWayAndCityString
        urlProps.searchParams = {}
        urlProps.searchParams.txid = this.props.prevCond.get("transactionID")
        urlProps.searchParams.depdate = depdate
        Converter.toNewListPage(urlProps, ubtSourceInfoOfThis)


    }


    render() {

        let { prevCond, lowRPrice, lowestRPrice, recommend, fetchLowPrice, showLowPrice, onEndDateChange, workingDays, isLogin, owRecommendRtSwitchV2, userAttr, priceItemsConfigs, minPrice } = this.props
        let { priceItems, lowestPrice, lowPriceItemCount, isConfigureAirline } = priceItemsConfigs,
            cabin = prevCond.get('cabin'),
            flightSegments = prevCond.get('flightSegments'),
            departDate = flightSegments.getIn([0, 'departureDate']),
            listMinPrice = minPrice.getIn([departDate, "totalPrice"]),
            show = false,
            showLowPriceCount = OwRecommendRtConfig.lowPriceCount,
            locationToRoundListPage = this.locationToRoundListPage.bind(this)
        if (owRecommendRtSwitchV2) {
            if (isConfigureAirline && lowPriceItemCount >= showLowPriceCount && cabin == "Y_S" && lowestPrice <= listMinPrice * OwRecommendRtConfig.lowestAirlinePriceRatio && isLogin && userAttr) {
                show = true
            } else if (isConfigureAirline && lowPriceItemCount >= showLowPriceCount && cabin == "Y_S" && lowestPrice <= listMinPrice * OwRecommendRtConfig.lowestAirlinePriceRatio && !isLogin) {
                show = true
            }
        } else {
            if (lowPriceItemCount >= showLowPriceCount) {
                show = true
            }
        }
        if (!show) {
            LIST_UBT_KEY_TYPES.OW_RECOMMEND_RT_LOAD.value.process("ad_roundway_show", "0")
            return null
        }
        LIST_UBT_KEY_TYPES.OW_RECOMMEND_RT_LOAD.value.process("ad_roundway_show", "1")

        // console.log(this.isConfigureAirline())
        let endDate = recommend.get('endDate'),
            selectedCity = {},
            flightLowRPrice = lowRPrice.get(`${flightSegments.getIn([0, 'departureCityCode'])}-${flightSegments.getIn([0, 'arrivalCityCode'])}-${cabin}`),
            onEndDateSelected = (dayInfo) => {
                onEndDateChange(dayInfo)
                LIST_UBT_KEY_TYPES.OW_RECOMMEND_RT_CLICK.value.process("ad_roundway_moredate_backdate", "1")
            }

        let fromCode = flightSegments.getIn([0, 'departureCityCode']),
            toCode = flightSegments.getIn([0, 'arrivalCityCode'])

        selectedCity = Immutable.fromJS({
            owDCity: {
                Code: fromCode
            },
            owACity: {
                Code: toCode
            }
        })


        return (
            <div className='flight-round-list'>
                <h2 className='round-tit'>往返一起订，省钱又省心</h2>
                <div className='round-list-bd'>
                    {priceItems.map((item, index) => {
                        let sequence = [],
                            ubtPrice = item.lowPrice == '--' ? '' : item.lowPrice,
                            otherParams = {
                                Price: ubtPrice,
                                IsTax: 'T'
                            }
                        sequence.push({
                            From: fromCode,
                            To: toCode,
                            StartTime: item.departDate.format('YYYY-MM-DD')
                        })
                        sequence.push({
                            From: toCode,
                            To: fromCode,
                            StartTime: item.endDate.format('YYYY-MM-DD')
                        })

                        return (
                            <UbtBoundary tracker={ListUbtGenerator(LIST_UBT_GROUP_TYPES.BASIC_DATA_FLOW, LIST_UBT_KEY_TYPES.RECOMMEND_FLIGHT_LOAD, 11, index + 1, "TopInfo",
                                sequence, otherParams)} key={shortid.generate()}>
                                <div className='round-item' key={index} onClick={this.onPriceItemClick.bind(this, item.departDate, item.endDate, index + 1, ubtPrice)}>
                                    <em className='item-rest'>{item.restOrWork}</em>
                                    <span className='item-go'>{item.departDate.format('MM-DD')}&nbsp;{item.departWeek}去</span>
                                    <strong className='item-back'>{item.endDate.format('MM-DD')}&nbsp;{item.endWeek}返</strong>
                                    {item.lowPrice == lowestPrice ?
                                        <p className='price lowprice'>
                                            <span><dfn>&yen;</dfn>{item.lowPrice}</span>
                                            <i className='tag tag-lowprice'>低</i>
                                        </p> :
                                        <p className='price'>
                                            <span><dfn>&yen;</dfn>{item.lowPrice}</span>
                                        </p>}
                                </div>
                            </UbtBoundary>
                        )
                    })}


                    <div className='round-more' onClick={this.onPopupCalendar.bind(this)}>
                        <span className='more-text'>更多日期</span>
                        <em className='more-arrow'><i className='arrow-right-bold-blue'></i></em>
                    </div>
                    {this.state.showCalendarPopup ? <PopDialog
                        contents={
                            <ModifyDateInfo
                                label="yyyy-mm-dd"
                                date={endDate}
                                start={departDate}
                                end={endDate}
                                min={departDate}
                                max={moment().add(1, "year")}
                                showWeek={false}
                                mode="arrivalTime"
                                cabin={cabin.toUpperCase()}
                                selectedCity={selectedCity}
                                flightWay={'RT'}
                                showLowPrice={showLowPrice}
                                fetchLowPrice={fetchLowPrice}
                                lowRPrice={lowRPrice}
                                lowestRPrice={lowestRPrice}
                                onDateChange={onEndDateSelected}
                                title={DATE_PICKER_TITLE['RT'].value.arrival}
                                footerTips={['所选价格均为单成人往返含税总价'].concat(DATE_PICKER_FOOTER_TIPS.TIME.value['RT'].arrival)}
                                workingDays={workingDays}
                                inline={true}
                                calendarMode="popup"
                                calendarAddon={popupCalendarFooter({ departDate, endDate, flightLowRPrice, locationToRoundListPage, cabin })}
                                onCalendarHide={this.hideCalendarPopup.bind(this)}
                            />
                        }
                        onClickBackground={this.hideCalendarPopup.bind(this)}
                    /> : null}
                </div>
            </div>
        )
    }
}


export default OwRecommendRt
